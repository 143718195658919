import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload,NoticeBar  } from 'vant';
import 'lib-flexible/flexible'
import './utils/style.less';
// import i18n from './i18n';
import VueI18n from './i18n'
import animate from 'animate.css'
import VueClipboard from 'vue-clipboard2'
import '@vant/touch-emulator'
// 路由发生变化修改页面title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
createApp(App).use(store).use(router).use(Lazyload).use(vant).use(VueI18n).use(animate).use(VueClipboard).use(NoticeBar ).mount('#app')
