<template>
  <div class="Rechargecoin" style="padding-bottom: 30px">
    <header>
      <div class="g-header" style="background: #fff">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#040925" size="20px" />
        </div>
        <div class="middle fs-18">{{ this.seletCoinItem.title + " " + $t("cunru") }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="mescroll g-content">
      <div class="qrcode-coin">
        <div class="qrcode-coin-container">
          <!-- <div class="qrcode-coin-QR">
                  <img id="shareImg" v-if="selectChainObj.img != ''" :src="selectChainObj.img" alt="" srcset="">
                </div> -->
          <!-- <div class="qrcode-coin-btn">
                  <button @click="saveQr()">{{ $t('Rechargecoin.downbtn') }}</button>
                </div> -->
          <div class="qrcode-coin-text">
            <p style="color: #c0c0c0">{{ $t("Rechargecoin.downurl") }}:</p>
            <p style="color: #dcdcdc">{{ seletCoinItem.address }}</p>
          </div>
          <div class="qrcode-coin-btn1">
            <button @click="copyClick(seletCoinItem.address)">
              {{ $t("Rechargecoin.copybtn") }}
            </button>
          </div>
        </div>
      </div>
      <div class="recharge-coin">
        <div class="recharge-coin-container">
          <div class="recharge-coin-item">
            <label for="amount" color="#F5F5F5">{{ $t("Rechargecoin.amount") }}</label>
            <input
              v-model="form.amount"
              type="text"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              :placeholder="$t('Rechargecoin.amountplaceholder')"
              id="amount"
              name="amount"
            />
          </div>

          <div
            class="recharge-coin-item"
            style="padding: 15px 0 0 0"
            v-if="form.product_id != 1"
          >
            <label for="amount" color="#F5F5F5">{{
              $t("Rechargecoin.transamount")
            }}</label>

            <input
              disabled
              :value="
                canUserWallet.balance == ''
                  ? ''
                  : Number(canUserWallet.balance).toFixed(2)
              "
              type="text"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              :placeholder="$t('Rechargecoin.amountplaceholder')"
              id="transamount"
              name="transamount"
            />
            <!-- <input v-model="form.transamount" type="text" oninput="value=value.replace(/[^0-9.]/g,'')" :placeholder="$t('Rechargecoin.amountplaceholder')" id="transamount" name="transamount"> -->
          </div>

          <div class="recharge-coin-item" style="padding: 15px 0; border: none">
            <label color="#F5F5F5">{{ $t("Rechargecoin.selectcontract") }}</label>
            <van-row type="flex" gutter="20" style="margin-top: 15px">
              <van-col span="8" v-for="(item, key) in chainList" :key="key">
                <van-button
                  plain
                  :type="form.wallet_id == item.id ? 'info' : 'default'"
                  size="small"
                  @click="emitChainItemClick(item)"
                >
                  {{ item.chain }}</van-button
                >
              </van-col>
            </van-row>
          </div>

          <div class="recharge-coin-item">
            <label for="amount" color="#F5F5F5">{{
              $t("Rechargecoin.walletaddress")
            }}</label>
            <input
              v-model="form.address"
              type="text"
              :placeholder="$t('Rechargecoin.walletaddressplaceholder')"
              id="amount"
              name="amount"
            />
          </div>
          <div class="recharge-coin-item" style="border: none; padding: 15px 0">
            <button @click="submit()" class="recharge-coin-confirm">
              {{ $t("Rechargecoin.confirmbtn") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coinApi from "@/api/coin";
import userApi from "@/api/user";
export default {
  name: "rechargecoin",
  inject: ["reload"],
  data() {
    return {
      show: false,
      selectChainObj: {
        id: 0,
        product_id: 0,
        currency_to: "",
        chain: "",
        address: "",
        remark: "",
        img: "",
        status: 0,
      },
      seletCoinItem: {
        base_currency: "",
        chain: [],
        icon: "",
      },
      chainList: [],
      form: {
        wallet_id: "", //钱包ID
        amount: "",
        product_id: this.$route.query.product_id,
        address: "",
        type: "1",
      },
    };
  },
  created() {
    this.apiGetCanCunCoinInfoHandel();
  },
  mounted() {},
  methods: {
    saveQr() {
      let src = document.getElementById("shareImg").src;
      let alink = document.createElement("a");
      alink.href = src;
      alink.download = this.$t("Rechargecoin.sharecode");
      alink.click();
    },
    // 选择网络触发
    emitChainItemClick(item) {
      this.selectChainObj = item;
      this.seletCoinItem = item;
      this.form.wallet_id = item.id;
    },
    // 点击复制
    // copyClick(msg) {
    //   this.$copyText(msg).then(()=>{ this.$toast.success(this.i18n.copySuccessText) },(e)=>{ console.log(e) })
    // },

    // 选择网络触发
    // emitChainItemClick(item) {
    //   this.selectChainObj = item
    //   this.form.wallet_id = item.id
    // },
    // 点击复制
    copyClick(msg) {
      this.$copyText(msg).then(
        () => {
          this.$toast.success(this.$t("rechargeCoinTwo.copySuccessText"));
        },
        (e) => {
          console.log(e);
        }
      );
    },
    apiGetCanCunCoinInfoHandel() {
      coinApi
        .wallet(this.form.product_id)
        .then((data) => {
          console.log(data.data);
          this.chainList = data.data.list;
          this.seletCoinItem = data.data.list[0];
          this.form.wallet_id = data.data.list[0].id;
          console.log(data);
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    changego() {
      this.$router.go(-1);
    },
    submit() {
      if (!this.form.amount) {
        this.$toast.fail(this.$t("Withdrawcoin.writeamount"));
      }
      if (!this.form.address) {
        this.$toast.fail(this.$t("Withdrawcoin.writeaddress"));
      }

      let that = this;
      userApi
        .rechargeCoin(this.form)
        .then((data) => {
          if (data.code) {
            this.$toast.success(data.msg);

            setTimeout(function () {
              that.refresh();
            }, 500);
          } else {
            this.$toast.fail(data.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    refresh() {
      this.$router.go(0);
    },
  },
  computed: {
    canUserWallet() {
      if (this.form.amount == "")
        return {
          balance: "",
          currency: this.seletCoinItem.currency_to,
        };
      if (this.seletCoinItem)
        return {
          balance: this.form.amount * this.seletCoinItem.huilv,
          currency: this.seletCoinItem.currency_to,
        };
      console.log("$$$$$", this.form.amount * this.seletCoinItem.rate);
      return {
        balance: 0,
        currency: 0,
      };
    },
  },
};
</script>

<style lang="less">
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.slide-btn {
  padding: 0.24rem;
}
.van-button--plain.van-button--info {
  color: #1989fa !important;
}
.mescroll {
  // border: red solid 1px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}
.qrcode-coin {
  // border: red solid 1px;
  width: 100%;
  height: 300px;
  padding: 14px;
  .qrcode-coin-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // border: red solid 1px;
    background-color: #232425;
    width: 100%;
    height: 100%;
    .qrcode-coin-QR {
      // border:red solid 1px;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .qrcode-coin-btn {
      // border: red solid 1px;
      height: 30px;
      button {
        // border: red solid 1px;
        height: 100%;
        width: 100px;
        border-radius: 15px;
        border: #ff9912 solid 1px;
        background-color: #ff9912;
        color: #1a243f;
      }
    }
    .qrcode-coin-text {
      // border: red solid 1px;
      // height: 60px;
      word-break: break-all;
      padding-left: 5px;
      padding-right: 5px;
      p {
        display: block;
        word-wrap: break-word;
        word-break: break-word;
        text-align: center;
      }
    }
    .qrcode-coin-btn1 {
      // border: red solid 1px;
      height: 30px;
      button {
        // border: red solid 1px;
        height: 100%;
        width: 100px;
        border-radius: 15px;
        border: #ff9912 solid 1px;
        background-color: #ff9912;
        color: #fff;
      }
    }
  }
}
.recharge-coin {
  // border: red solid 1px;
  // height: 300px;
  padding: 14px;
  color: #fff;
  .recharge-coin-container {
    // border: red solid 1px;
    // height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .recharge-coin-item {
      // border:red solid 1px;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: #1a243f solid 1px;
      label {
        color: #1a243f;
        text-align: left;
        font-size: 15px;
      }
      input {
        // border: red solid 1px;
        display: block;
        color: #1a243f;
        font-size: 15px;
        padding: 15px 0;
        text-align: left;
      }
      .van-col {
        button {
          width: 100%;
          border-radius: 6px;
          color: #232425;
        }
      }
      .recharge-coin-confirm {
        margin: 0 auto;
        border: #1989fa solid 1px;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        background-color: #1989fa;
      }
    }
  }
}
</style>
