<template>
  <div class="Rechargecoin" style="padding-bottom: 30px">
    <header>
      <div class="g-header" style="background: #fff">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#040925" size="20px" />
        </div>
        <div class="middle fs-18">{{ $t("Withdrawcoin.title") }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="mescroll g-content">
      <!-- <div class="qrcode-content">
            <div class="qrcode-content-container">
                <div class="qrcode-content-QR">
                  <img id="shareImg" v-if="selectChainObj.img != ''" :src="selectChainObj.img" alt="" srcset="">
                </div>
                <div class="qrcode-content-btn">
                  <button @click="saveQr()">点击下载</button>
                </div>
                <div class="qrcode-content-text">
                  <p style="color: #C0C0C0;">存款地址:</p>
                  <p style="color: #DCDCDC;">{{ selectChainObj.address }}</p>
                </div>
                <div class="qrcode-content-btn1">
                  <button @click="copyClick(selectChainObj.address)">点击复制</button>
                </div>
            </div>
        </div> -->
      <div class="withdrawal-coin">
        <div class="withdrawal-coin-container">
          <div class="withdrawal-coin-item" style="border-color: #c0c0c0">
            <label color="#1a243f">{{ $t("Withdrawcoin.currency") }}</label>
            <input type="text" value="USDT" disabled style="color: #888888" />
          </div>
          <div class="withdrawal-coin-item" style="padding: 15px 0; border: none">
            <label color="#1a243f">{{ $t("Withdrawcoin.selectcontract") }}</label>
            <van-row type="flex" gutter="20" style="margin-top: 15px">
              <van-col span="8" v-for="(item, key) in chainList" :key="key">
                <van-button
                  plain
                  :type="form.wallet_id == item.id ? 'info' : 'default'"
                  size="small"
                  @click="emitChainItemClick(item)"
                >
                  {{ item.chain }}</van-button
                >
              </van-col>
            </van-row>
          </div>

          <div class="withdrawal-coin-item" style="position: relative">
            <label for="amount" color="#1a243f">{{ $t("Withdrawcoin.amount") }}</label>
            <p class="yue">
              {{ $t("Withdrawcoin.yue") }}:<font>{{ user.money }}</font>
            </p>
            <input
              v-model="form.amount"
              type="text"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              :placeholder="$t('Withdrawcoin.yueplaceholder')"
              id="amount"
              name="amount"
            />
          </div>
          <div class="withdrawal-coin-item" style="height: 15px; border: none"></div>
          <div class="withdrawal-coin-item" style="margin-top: 15px">
            <label for="amount" color="#1a243f">{{
              $t("Withdrawcoin.walletaddress")
            }}</label>
            <input
              v-model="form.address"
              type="text"
              :placeholder="$t('Withdrawcoin.walletaddressplaceholder')"
              id="amount"
              name="amount"
            />
          </div>
          <div class="withdrawal-coin-item" style="border: none; padding: 15px 0">
            <button @click="submit()" class="withdrawal-coin-confirm">
              {{ $t("Withdrawcoin.confirmbtn") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 安全码弹出框提交申请的时候弹出 -->
    <van-popup v-model:show="showsecurity" class="securityalert">
      <div>
        <div class="safe-code">
          <div class="title ta-c c_fff">{{ $t("shuruanquanma") }}</div>
          <div class="paycode">
            <van-password-input
              :value="showsecurityValue"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
            />
          </div>
        </div>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="showsecurityValue"
      :show="showsecurity"
      @blur="showsecurity = false"
    />
  </div>
</template>

<script>
import coinApi from "@/api/coin";
import userApi from "@/api/user";
export default {
  name: "rechargecoin",
  data() {
    return {
      show: false,
      showsecurity: false,
      showsecurityValue: "",
      showKeyboard: true,
      selectChainObj: {
        id: 0,
        product_id: 0,
        chain: "",
        address: "",
        remark: "",
        img: "",
        status: 0,
      },
      chainList: [],
      user: [],
      // infoList: [],
      isTrue: null,
      form: {
        wallet_id: "", //钱包ID
        amount: "",
        address: "",
        type: "2",
        showsecurityValue: "",
      },
    };
  },

  created() {
    this.userInfo();
    this.apiGetCanCunCoinInfoHandel();
    userApi.safetyInfo().then((data) => {
      this.isTrue = data.data.isTrue;
    });
  },
  mounted() {},
  watch: {
    showsecurityValue(value) {
      if (value.length === 6) {
        // 调用接口
        this.showsecurity = false;
        this.Withdrawal();
      }
    },
  },
  methods: {
    saveQr() {
      let src = document.getElementById("shareImg").src;
      let alink = document.createElement("a");
      alink.href = src;
      alink.download = this.$t("Withdrawcoin.sharecode");
      alink.click();
    },
    // 选择网络触发
    emitChainItemClick(item) {
      this.selectChainObj = item;
      this.form.wallet_id = item.id;
    },
    // 点击复制
    // copyClick(msg) {
    //   this.$copyText(msg).then(()=>{ this.$toast.success(this.i18n.copySuccessText) },(e)=>{ console.log(e) })
    // },

    // 选择网络触发
    // emitChainItemClick(item) {
    //   this.selectChainObj = item
    //   this.form.wallet_id = item.id
    // },
    userInfo() {
      userApi.userInfo().then((data) => {
        this.user = data.msg;
        // this.infoList = data.data;
        // this.$router.push({
        //   path: "/baseinfo",
        //   query: {
        //     name: data.msg.username,
        //   },
        // });
      });
    },
    // 点击复制
    copyClick(msg) {
      this.$copyText(msg).then(
        () => {
          this.$toast.success(this.$t("rechargeCoinTwo.copySuccessText"));
        },
        (e) => {
          console.log(e);
        }
      );
    },
    apiGetCanCunCoinInfoHandel() {
      coinApi
        .wallet(1)
        .then((data) => {
          console.log(data.data);
          this.chainList = data.data.list;
          this.selectChainObj = data.data.list[0];
          this.form.wallet_id = data.data.list[0].id;
          console.log(data);
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    changego() {
      this.$router.go(-1);
    },
    submit() {
      if (!this.form.amount) {
        this.$toast.fail(this.$t("Withdrawcoin.writeamount"));
        return;
      }
      if (!this.form.address) {
        this.$toast.fail(this.$t("Withdrawcoin.writeaddress"));
        return;
      }
      if (this.form.amount > this.user.money) {
        this.$toast.fail(this.$t("Withdrawcoin.writesuper"));
        return;
      }

      // 个人资料完整验证废除
      //判断是否游客
      if (!this.usercrud) {
        userApi.userInfo().then((data) => {
          this.user = data.msg;
          this.$router.push({
            path: "/baseinfo",
            query: {
              name: data.msg.username,
            },
          });
        });
        return;
      }
      // if (!this.infoList.pay_list) {
      //   // 是否有银行卡

      //   this.$router.push({
      //     path: "/bankCardManage",
      //     query: {
      //       type: 0,
      //     },
      //   });
      // } else
      if (!this.isTrue) {
        // 是否有安全码
        this.$router.push({
          path: "/setSecurityCode",
          query: {
            isTrue: this.isTrue,
          },
        });
      } else {
        // 提现请求
        console.log("$$$$$$", this.form.amount);
        if (!this.form.amount) {
          this.$toast(this.$t("Tips.pltxje"));
          return;
        } else if (Number(this.form.amount) < 100) {
          this.$toast(this.$t("Tips.zdtx"));
          return;
        } else {
          this.showsecurity = true;
        }
      }
    },
    Withdrawal() {
      let that = this;
      this.form.showsecurityValue = this.showsecurityValue;
      userApi
        .withdrawalCoin(this.form)
        .then((data) => {
          if (data.code) {
            this.$toast.success(data.msg);
            setTimeout(function () {
              that.refresh();
            }, 500);
          } else {
            this.$toast.fail(data.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    refresh() {
      this.$router.go(0);
    },
  },
  computed: {
    group_id() {
      return this.$store.state.group_id;
    },
    usercrud() {
      console.log(this.$store.state.usercrud, "12");
      return this.$store.state.usercrud;
    },
  },
};
</script>

<style lang="less" scoped>
.van-number-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #1a243f;
  -webkit-user-select: none;
  user-select: none;
}
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.slide-btn {
  padding: 0.24rem;
}
.van-button--plain.van-button--info {
  color: #1989fa !important;
}
.mescroll {
  // border: red solid 1px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}

.withdrawal-coin {
  // border: red solid 1px;
  // height: 300px;
  padding: 14px;
  color: #fff;
  .withdrawal-coin-container {
    // border: red solid 1px;
    // height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .withdrawal-coin-item {
      // border:red solid 1px;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: #f5f5f5 solid 1px;
      .yue {
        font-size: 10px;
        position: absolute;
        bottom: -20px;
        left: 0;
        color: #e57b13;
        // border: red solid 1px;
      }
      label {
        color: #1a243f;
        text-align: left;
        font-size: 15px;
      }
      input {
        // border: red solid 1px;
        display: block;
        color: #1a243f;
        font-size: 15px;
        padding: 15px 0;
        text-align: left;
      }
      .van-col {
        button {
          width: 100%;
          border-radius: 6px;
          color: #232425;
        }
      }
      .withdrawal-coin-confirm {
        margin: 0 auto;
        border: #1989fa solid 1px;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        background-color: #1989fa;
      }
    }
  }
}
</style>
