<template>
	<div class="v_my_center">
		<header>
            <div class="g-header" style="background:#fff">
                <div class="left" @click="changego">
                    <van-icon name="arrow-left" color="#040925" size="20px" />
                </div>
                <div class="middle fs-18">{{ $t('lianxikefu') }}</div>
                <div class="right">
                    <div class="bg-icon bg-icon-setting"></div>
                </div>
            </div>
            <div class="blockHeight"></div>
        </header>
		<iframe :src="kfUrl" frameborder="0" :width="windowWidth" :height="windowHeight"></iframe>
	</div>
</template>

<script>
	import homeApi from "@/api/home"
	export default {
		data() {
			return {
				kfUrl: "",
				windowWidth: document.documentElement.clientWidth + 'px', //实时屏幕宽度
				windowHeight: document.documentElement.clientHeight + 'px', //实时屏幕高度
			}
		},
		onLoad() {

		},

		created() {
            homeApi.guestService().then((data) => {
                console.log(data.data);
                this.kfUrl = data.data.guest_service
            }).catch((err) => {
                    this.$toast.clear();
                    this.$toast.fail(err.msg);
                });
			// let that = this;
			// apiGetConfig().then((r) => {
			// 	// window.open(r.data.system.kefuUrl);
			// 	that.kfUrl = r.data.system.kefuUrl;
			// });
		},

		methods: {
            changego() {
                this.$router.go(-1);
            },
		},

	}
</script>

<style lang='less'>
	.v_my_center {
		height: 100%;
		overflow: auto;
	}
</style>
