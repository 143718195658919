<template>
  <div class="Rechargecoin" style="padding-bottom: 30px">
    <header>
      <div class="g-header" style="background: #fff">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#040925" size="20px" />
        </div>
        <div class="middle fs-18" v-if="$route.query.kind == 1">{{ $t("zhuanchu") }}</div>
        <div class="middle fs-18" v-if="$route.query.kind == 2">{{ $t("cunru") }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="mescroll g-content">
      <div class="passage-content">
        <div class="passage-content-container">
          <div class="passage-content-QR">
            <img
              :src="
                $route.query.kind == 1
                  ? require('@/assets/image/withdrawal.png')
                  : require('@/assets/image/save.png')
              "
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="recharge-passage">
        <div class="recharge-passage-container">
          <div
            v-if="$route.query.kind == 2"
            class="recharge-passage-item"
            @click="
              (event) =>
                this.$router.push({ path: '/rechargecoin', query: { product_id: 1 } })
            "
          >
            <p>{{ $t("Recharge.usdtrecharge") }}</p>
          </div>
          <div
            v-if="$route.query.kind == 2"
            class="recharge-passage-item"
            @click="
              (event) =>
                this.$router.push({ path: '/rechargecoin', query: { product_id: 2 } })
            "
          >
            <p>{{ $t("Recharge.btcrecharge") }}</p>
          </div>
          <div
            v-if="$route.query.kind == 2"
            class="recharge-passage-item"
            @click="
              (event) =>
                this.$router.push({ path: '/rechargecoin', query: { product_id: 3 } })
            "
          >
            <p>{{ $t("Recharge.ethrecharge") }}</p>
          </div>
          <div
            v-if="$route.query.kind == 1"
            class="recharge-passage-item"
            @click="(event) => this.$router.push({ path: '/withdrawalcoin' })"
          >
            <p>{{ $t("Recharge.usdtwithdrawal") }}</p>
          </div>
          <div
            v-if="$route.query.kind == 2"
            class="recharge-passage-item"
            @click="(event) => this.$router.push({ path: '/bankrecharge' })"
          >
            <p>{{ $t("Recharge.bankrecharge") }}</p>
          </div>
          <div
            v-if="$route.query.kind == 1"
            class="recharge-passage-item"
            @click="(event) => this.$router.push({ path: '/bankwithdrawal' })"
          >
            <p>{{ $t("Recharge.bankwithdrawal") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recharge",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    changego() {
      this.$router.go(-1);
    },
  },
  computed: {},
};
</script>

<style lang="less">
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.slide-btn {
  padding: 0.24rem;
}

.van-button--plain.van-button--info {
  color: #1989fa !important;
}

.mescroll {
  // border: red solid 1px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}

.passage-content {
  // border: red solid 1px;
  width: 100%;
  height: 226px;
  padding: 14px;

  .passage-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // border: red solid 1px;
    background-color: #232425;
    width: 100%;
    height: 100%;

    .passage-content-QR {
      // border:red solid 1px;
      width: inherit;
      height: inherit;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.recharge-passage {
  // border: red solid 1px;
  // height: 300px;
  padding: 14px;
  color: #fff;
  margin-bottom: 60px;

  .recharge-passage-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .recharge-passage-item {
      border: #191970 solid 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      background-color: #191970;
      border-radius: 15px;
    }
    .recharge-passage-item:nth-of-type(n + 2) {
      margin-top: 15px;
    }
  }
}
</style>
