<template>
  <div class="lt-list">
    <div
      class="lt-item"
      v-for="(item, index) in list"
      :key="index"
      @click="$router.push({ path: '/lottery', query: { item: index + 1 } })"
    >
      <div class="t1">{{ item.name }}</div>
      <div class="t2">{{ item.issue.last_issue }}{{ $t("lun") }}</div>
      <div class="t3" :style="lang != 'zh' && lang != 'tw' ? 'transform:scale(0.7)' : ''">
        {{ $t("hezhi") }}：{{ item.issue.last_code || 0 }}
      </div>
    </div>
  </div>
</template>
<script>
import homeApi from "@/api/home";
export default {
  data() {
    return {
      lang: "en",
      list: [],
    };
  },
  computed: {},
  methods: {
    info() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .Lotteryinfo(1)
        .then((data) => {
          console.log(data.data);
          this.list = data.data.gameList;
          this.$emit("gameList", data.data);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {},
  created() {
    this.info();
    this.lang = localStorage.getItem("locale");
  },
};
</script>
<style lang="less" scoped>
.lt-lis ::-webkit-scrollbar {
  display: none;
}
.lt-list {
  display: flex;
  justify-content: start;
  align-items: stretch;
  padding: 0 0.2rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  //    padding-bottom: 20px;
}
::-webkit-scrollbar {
  display: none;
}
.lt-item {
  display: inline-block;
  flex: 1;
  min-width: 3rem;
  margin-right: 0.3rem;
  padding: 0.4rem 0;
  border-radius: 4px;
  background: #f0e9e9;
  text-align: center;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 隐藏溢出文本 */
  text-overflow: ellipsis; /* 以省略号代替溢出文本 */
}
.t1 {
  color: #1a243f;
  font-size: 0.35rem;
}
.t2 {
  padding: 0.2rem 0.1rem;
  color: #1a243f;
  font-size: 0.3rem;
  word-break: break-all;
  white-space: normal;
}
.t3 {
  color: #4bb3e8;
  font-size: 0.44rem;
}
</style>
